<template>
  <div class="page">
    <div class="content">
      <div class="articles">
        <ul class="list">
          <router-link tag="li" class="li" :to="'/teachs/'+item.id" v-for="(item,xh) in dataList" :key="xh">
            <div class="title">{{item.title}}</div>
            <div class="time">{{item.mtime}}</div>
            <div class="ms">
              <div class="jl" v-html="jie(item.nr)"></div>
              <div class="tpic" v-if="item.tpic"><img :src="item.tpic"></div>
            </div>
          </router-link>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name: 'articles',
    data() {
        return {
            articles: []
        }
    },
    props: {
        dataList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created() {

    },
    methods: {
        jie(html) {
            //let much
            let reg = new RegExp('<[^>]+>', 'gi');  //过滤所有的html标签，不包括内容
            if (typeof html != 'string') {  //不是字符串
                return html;
            }
            let last = html.replace(reg, '')
            if (window.innerWidth > 768) {
                //much = 89
            } else {
                //much = 20
            }

            //let lh = last.substr(0, much)
            return '<p>' + last + '</p>'
        }
    },
    computed: {}
}
</script>

